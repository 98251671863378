import React from "react";
import styled from "styled-components";
import { Grid } from "basis";

import { LoadingMessage, NavigationalLayout } from "../components";
import { BREAKPOINT, COLOR } from "../../../components/theme";
import { Text } from "../../../components";


const StyledContainer = styled.div`
  padding: 20px 15px;
  background-color: ${COLOR.WHITE};

  @media (min-width: ${BREAKPOINT.SIZE_TABLET}) {
    border-radius: 10px;
    padding: 20px 30px;
  }
`;

const SelectScene = ({ children, loading = false }) => {
  if (loading) {
    return (
      <NavigationalLayout>
        <LoadingMessage message="Please wait while we fetch your transactions.." />
      </NavigationalLayout>
    );
  }

  return (
    <NavigationalLayout>
      <StyledContainer>
      <Text textStyle="heading3">Switch Store</Text>
        <Grid preset="page" rowsGap={0}>
          {children}
        </Grid>
      </StyledContainer>
    </NavigationalLayout>
  );
};

const FullWidth = ({ children }) => (
  <Grid.Item colSpan="0-12">{children}</Grid.Item>
);

SelectScene.FullWidth = FullWidth;

export default SelectScene;
