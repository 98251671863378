import React from "react";
import { Container, Stack } from "basis";
import { Icon, Text } from "../../../components";

export const NoMerchants = ({ hasFilter = false }) => {
  return (
    <Container margin="8 0">
      <>
        {!hasFilter && (
          <Stack align="center" gap="2">
            <Icon type="questionBox" size="80px" />

            <Text textStyle="heading5" as="h5" align="center" margin="2 0">
              No Merchants found
            </Text>

            <Text textStyle="body2" align="center" margin="0 0 12 0">
              We couldn't find any merchants try another merchant ID using search box provided.
            </Text>
          </Stack>
        )}
      </>
    </Container>
  );
};
