import React, { useState } from "react";
import { useMerchantAuth } from "../../../core/auth";
import { transformProfiles } from "../../../layout/merchant/utils";
import { MerchantFilter } from "../../../layout/merchant/components/MerchantFilter";
import SelectScene from "../../../layout/merchant/merchantselect/SelectScene";
import { MerchantSelectTable } from "../../../layout/merchant/components/MerchantSelectTable";

export const TRANSACTION_FILTERS = {
    ALL: "All"
};
const SelectMerchant = () => {
    const [selectedFilter, setSelectedFilter] = useState(TRANSACTION_FILTERS.ALL);
    const [searchText, setSearchText] = useState("");
    const {
        profile,
        linkedProfiles,
        isLoggedIn,
    } = useMerchantAuth();

    if (!isLoggedIn()) {
        return null;
    }


    const selectableProfiles = transformProfiles(linkedProfiles)?.filter(
        ({ merchantId }) => profile?.merchantId !== merchantId
    )
        .map((p) => {
            return { id: p.merchantId, val: p.merchantName }
        });
    return (
        <SelectScene>
            <SelectScene.FullWidth>
                <MerchantFilter
                    selectedFilter={selectedFilter}
                    searchText={searchText}
                    onFilterChange={(val) => setSelectedFilter(val)}
                    onSearchTextChange={(val) => setSearchText(val)}
                />
            </SelectScene.FullWidth>

            <SelectScene.FullWidth>
                <MerchantSelectTable
                    selectedFilter={selectedFilter}
                    getAllMerchants={selectableProfiles}
                    searchText={searchText}
                />
            </SelectScene.FullWidth>

        </SelectScene>
    );
};
export default SelectMerchant;