import React, { useEffect, useState } from "react";
import { Text } from "basis";

import {
  Button,
  Flex,
  HideOnTablet,
  Loading,
  Responsive,
  Select,
} from "../../../components";
import { useMerchantAuth } from "../../../core/auth";
import { logger } from "../../../core";

import fetchLinkedProfiles from "../../../core/auth/profile/fetchLinkedProfiles"
import {
  filterAndSortTransactions,
  SORT_DIRECTON,
} from "../utils";

import { } from "./TableHeadings";
import {
  RESULT_PER_PAGE_OPTIONS
} from "../constants";
import { MerchantTableHeadings } from "./MerchantTableHeading";
import MerchantRow from "./MerchantRow";
import { NoMerchants } from "./NoMerchants";
import { TRANSACTION_FILTERS_SEARCHABLE } from "./MerchantFilter";

const INITIAL_SEARCH_CONFIG = {
  resultsPerPage: 50,
  nextToken: null,
};

export const MerchantSelectTable = ({
  selectedFilter,
  searchText,
  setLoading = () => { },
}) => {
  const [searchConfig, setSearchConfig] = useState(INITIAL_SEARCH_CONFIG);
  const [orders, setOrders] = useState([]);
  const [displayOrders, setDisplayOrders] = useState([]);
  const [fetchingOrders, setFetchingOrders] = useState(true);
  const [currentSort, setCurrentSort] = useState({
    field: "longDate",
    direction: SORT_DIRECTON.DESC,
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [disabled, setDisabled]= useState(false)

  const {
    profile,
    changeProfile
  } = useMerchantAuth();
  const changeResultsPerPage = (_, value) =>{
    setSearchConfig({ resultsPerPage: value, nextToken: null });
    if(disabled){
      setDisabled(false)
    }
  }

  const changeNextToken = (value) =>
    setSearchConfig((prevSearchConfig) => ({
      ...prevSearchConfig,
      nextToken: value,
    }));
  const handleSortChange = (value) => {
    if (currentSort?.field === value) {
      const newSortDirection =
        currentSort?.direction === SORT_DIRECTON.DESC
          ? SORT_DIRECTON.ASC
          : SORT_DIRECTON.DESC;

      setCurrentSort({ ...currentSort, direction: newSortDirection });
      return;
    }

    setCurrentSort({ field: value, direction: SORT_DIRECTON.DESC });
  };

  const handlePrevClick = () => {
    const newStartIndex = currentIndex - Number(searchConfig?.resultsPerPage);
    if (newStartIndex < 0) {
      return;
    }

    setCurrentIndex(newStartIndex);
    if(searchConfig?.nextToken===''&& disabled===true){
      setDisabled(false)
    }
  };

  const handleNextClick = async () => {
    const newStartIndex = currentIndex + Number(searchConfig?.resultsPerPage);
    if (newStartIndex >= displayOrders?.length && searchConfig?.nextToken!=='') {
      setDisabled(false)
      await refreshOrders(searchConfig?.nextToken);
      setCurrentIndex(newStartIndex);
      return;
    }

    setCurrentIndex(newStartIndex);
  };

  const refreshOrders = async () => {
    if (
      searchText?.length < 1 && selectedFilter === TRANSACTION_FILTERS_SEARCHABLE.CUSTOMER_NAME
    ) {
     return orders;
    }
    if (selectedFilter === TRANSACTION_FILTERS_SEARCHABLE.CUSTOMER_NAME) {
      setOrders([]);
      setSearchConfig((prevSearchConfig) => ({
        ...prevSearchConfig,
        nextToken: '',
      }))
    }

    setFetchingOrders(true);
    const { profiles, error, success, nextToken } = await fetchLinkedProfiles(
      Number(searchConfig?.resultsPerPage),
      searchConfig?.nextToken,
      searchText,
    );
    if (!success) {
      setFetchingOrders(false);
      logger.error("Error while fetching transactions", error);

      return;
    }

    const transformedOrders = await profiles?.map((p) => {
      return { id: p.merchantId, val: p.merchantName }
    });
    // hide loaders only when data are ready for UI
    setFetchingOrders(false);
    setLoading(false);

    setOrders((prevOrders) => [...prevOrders, ...transformedOrders]);
    if(nextToken){
      setDisabled(false);
    changeNextToken(nextToken);
    }else{
      changeNextToken('')
      setDisabled(true)
    }
    if(transformedOrders?.length < searchConfig.resultsPerPage ){
      setDisabled(true);
    }
  };
  useEffect(()=>{
    if(displayOrders
        ?.slice(
          currentIndex,
          currentIndex + Number(searchConfig?.resultsPerPage)
        ).length < searchConfig?.resultsPerPage && displayOrders
        ?.slice(
          currentIndex,
          currentIndex + Number(searchConfig?.resultsPerPage)
        ).length !== searchConfig?.resultsPerPage&& searchConfig?.nextToken === '')
        {
          setDisabled(true)
        }else{
          setDisabled(false)
        }
  }, [currentIndex,selectedFilter,
    searchConfig.resultsPerPage,
    searchText,
    currentSort.field, 
    currentSort.direction])
  useEffect(() => {
    if (selectedFilter != TRANSACTION_FILTERS_SEARCHABLE.CUSTOMER_NAME) {
      setOrders([]);
    }
    if (selectedFilter === TRANSACTION_FILTERS_SEARCHABLE.CUSTOMER_NAME) {
      setSearchConfig((prevSearchConfig) => ({
        ...prevSearchConfig,
        nextToken: '',
      }))
    }
    refreshOrders("");
  }, [
    selectedFilter,
    searchConfig.resultsPerPage,
    searchText,
  ]);

  useEffect(() => {
    const filterAndSortOrders = async () => {
      const newDisplayOrders = filterAndSortTransactions({
        orders,
        filterText: "",
        extractSortField: (currRow) =>
          currentSort.field === "longDate"
            ? new Date(currRow?.createdAt)
            : currRow?.[currentSort.field],
        sortDirection: currentSort?.direction,
      });

      setCurrentIndex(0);
      setDisplayOrders(newDisplayOrders);
    };

    filterAndSortOrders();
  }, [orders, currentSort.field, currentSort.direction]);

  return (
    <>
      <HideOnTablet>
        <MerchantTableHeadings
          onSort={handleSortChange}
          sortTerm={currentSort?.field}
          sortType={currentSort?.direction}
        />
      </HideOnTablet>

      {fetchingOrders && <Loading />}

      {(!fetchingOrders && displayOrders?.length === 0) && (
        <NoMerchants />
      )}

      {displayOrders
        ?.slice(
          currentIndex,
          currentIndex + Number(searchConfig?.resultsPerPage)
        )
        .map((item) => (
          <MerchantRow
            key={item?.id}
            merchantId={item?.id}
            merchantDetails={item?.val}
            profile={profile}
            currentIndex={searchConfig.resultsPerPage}
            changeProfile={changeProfile}
          />
        ))}

      {displayOrders?.length > 0 && (
        <Responsive margin="32px 12px 0 0">
          <Flex>
            <Button
              smallFont
              showButton
              variant="secondary"
              margin="0 12px 0 0"
              disabled={currentIndex === 0}
              onClick={handlePrevClick}
            >
              &lt; Prev
            </Button>

            <Button
              smallFont
              showButton
              variant="secondary"
              loading={fetchingOrders}
              disabled={disabled}
              onClick={handleNextClick}
            >
              Next &gt;
            </Button>
          </Flex>

          <Flex alignContent="center">
            <Text color="grey.t75" margin="0 4 0 0">
              <strong>Results per page: </strong>
            </Text>

            <Select
              grey
              name="resultsPerPage"
              color="silver"
              selected={searchConfig?.resultsPerPage}
              options={RESULT_PER_PAGE_OPTIONS}
              onChange={changeResultsPerPage}
            />
          </Flex>
        </Responsive>
      )}
    </>
  );
};
