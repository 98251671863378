import React from "react";
import { Container, Stack, Text } from "basis";
import {
  Box,
  Column,
  DateRangePicker,
  Icon,
  Pill,
  Responsive,
  SearchInput,
} from "../../../components";
import {
  REFERENCE_NUMBER_REGEX,
} from "../constants";
import { COLOR } from "../../../components/theme";

export const TRANSACTION_FILTERS = {
  ALL: "All"
};

export const TRANSACTION_FILTERS_SEARCHABLE = {
  CUSTOMER_NAME: "Merchant ID",
};

export const SEARCH_TYPE = {
  CUSTOMER_NAME: "customerName",
};
export const MerchantFilter = ({
  selectedFilter,
  disabled,
  searchText,
  onFilterChange = () => {},
  onSearchTextChange = () => {},
}) => {
  const handleFilterChange = (value) => {
    if (disabled) {
      return;
    }

    onSearchTextChange("");
    onFilterChange(value);
  };

  const handleSearchTextChange = (value) => {
    if (disabled) {
      return;
    }

    onSearchTextChange(value);
  };


  return (
    <Box margin="18px 0" padding="12px 18px">
      <Column template="1fr" templateTablet="3fr 2fr">
        <Container margin="4 0 4 0">
          <Text margin="0 0 3">
            <strong>Filter by</strong>
          </Text>

          <Container margin="5 0 0 0">
            <Stack gap="2" direction="horizontal">
              {Object.values(TRANSACTION_FILTERS).map((value, idx) => (
                <Pill
                  key={`txn-filter${idx}`}
                  selected={value === selectedFilter}
                  onClick={() => handleFilterChange(value)}
                >
                  {value}
                </Pill>
              ))}

              {Object.values(TRANSACTION_FILTERS_SEARCHABLE).map(
                (value, idx) => (
                  <Pill
                    blue
                    key={`txn-filter-searchable${idx}`}
                    selected={value === selectedFilter}
                    onClick={() => handleFilterChange(value)}
                  >
                    <Icon
                      inline
                      type="iconSearch"
                      size="12px"
                      margin="0 8px 0 0"
                      color={
                        value === selectedFilter ? COLOR.WHITE : COLOR.BLACK
                      }
                    />
                    {value}
                  </Pill>
                )
              )}
            </Stack>
          </Container>
        </Container>

        <Container margin="4 0 4 0">
        
          {selectedFilter === TRANSACTION_FILTERS_SEARCHABLE.CUSTOMER_NAME && (
            <>
              <Text margin="0 0 3">
                <strong>Search by merchantID</strong>
              </Text>

              <Responsive>
                <SearchInput
                  placeholder="e.g. 000414701"
                  searchText={searchText}
                  validateFn={(val) => REFERENCE_NUMBER_REGEX.test(val)}
                  onSearch={handleSearchTextChange}
                />
              </Responsive>
            </>
          )}
        </Container>
      </Column>
    </Box>
  );
};
